import React from "react";

import './profile.css'

const scrollToContact = () => {
  const contactSection = document.getElementById("contact");
  if (contactSection) {
    contactSection.scrollIntoView({ behavior: "smooth" });
  }
};

const Profile = () =>{

  const openCV = () => {
    window.open('/assets/resume-example.pdf');
  };

  return (
    <section id="profile">
      <div className="section-pic-container">
        <img src="/assets/profile-pic.png" alt="Christo Johnson"></img>

      </div>
      <div className="section-text">
        <p className="section-text-p1">
          Hello I'm
        </p>
        <h1 className="title">
          Christo Johnson
        </h1>
        <p className="section-text-p2">
          Backend Developer
        </p>
        <div className="btn-container">
          <button className="btn btn-color-2" onClick={openCV}>
            Download CV
          </button>
          <button className="btn btn-color-1" onClick={scrollToContact}>
            Contact Info
          </button>
        </div>
        <div id="socials-container">
        <img
          src="/assets/linkedin.png"
          alt="My Github profile"
          className="icon"
          onClick={() => (window.location.href = "https://www.linkedin.com/in/christojohnsonv/")}
        />
        <img
          src="/assets/github.png"
          alt="My LinkedIn profile"
          className="icon"
          onClick={() => (window.location.href = "https://github.com/christojohnsonv/")}
        />
        </div>
      </div>
    </section>
  )
}

export default Profile