import React from "react";

import './About.css'

const About = () => {
  return (
    <section id="about">
      <p className="section-text-p1">Get To Know More</p>
      <h1 className="title">About Me</h1>
      <div className="section-container">
        <div className="section-pic-container">
          <img
            src="/assets/about-pic.jpg"
            alt="About Pic"
            className="about-pic"
          />
        </div>
        <div className="about-details-container">
          <div className="about-containers">
            <div className="details-container">
              <img src="/assets/experience.png" alt="Experience Icon" className="icon" />
              <h3>Experience</h3>
              <p>
                3+ years <br />
                Backend Development
              </p>
            </div>
            <div className="details-container">
              <img src="/assets/education.png" alt="Education Icon" className="icon" />
              <h3>Education</h3>
              <p>
                Master of Computer Application
                <br />
                Master of Commerce
                <br />
                Bachelor of commerce
              </p>
            </div>
          </div>
        </div>
      </div>
          <div className="text-container">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit
              necessitatibus nobis explicabo eum eligendi nostrum magnam culpa
              adipisci suscipit facilis, eos rerum saepe dolores pariatur amet
              enim laudantium perferendis laboriosam labore at fugit, maiores,
              veniam praesentium deserunt! Pariatur corrupti repudiandae
              repellendus molestias doloribus fugit placeat deserunt deleniti
              veniam omnis. Eos?
            </p>
          </div>
      <img
        src="/assets/arrow.png"
        alt="Arrow Icon"
        className="icon arrow"
        onClick={() => (window.location.href = '/#experience')}
        />
    </section>
  );
};

export default About;
