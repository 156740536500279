import React from "react";

import './homepage.css'
import NavBar from "./NavbarComponents/navbar";
import Profile from "./Profile/profile";
import About from "./About/About"


const HomePage = () => {
  return (

    <div className="home-body">
      <NavBar/>
      <Profile/>
      <About/>

    </div>
  )
}

export default HomePage